<template>
  <div id="img-zoomer-box">
    <img
      id="img-1"
      :src="imageSelected"
      @mousemove="zoomer"
    />
    <div
      id="img-2"
      v-bind:style="{'background-image': `url(${imageSelected})`}"
    />
  </div>
</template>

<script>
export default {
  props: {
    imageSelected: {
      type: String,
      default: "",
    },
  },
  name: "ZoomImageComponent",
  data() {
    return {};
  },
  methods: {
    zoomer: function (e) {
      let original = document.querySelector("#img-1"),
        magnified = document.querySelector("#img-2"),
        style = magnified.style,
        x = e.pageX - document.querySelector("#img-zoomer-box").offsetLeft,
        y = e.pageY - document.querySelector("#img-zoomer-box").offsetTop,
        imgWidth = original.offsetWidth,
        imgHeight = original.offsetHeight,
        xperc = (x / imgWidth) * 100,
        yperc = (y / imgHeight) * 100;

      //lets user scroll past right edge of image
      if (x > 0.01 * imgWidth) {
        xperc += 0.15 * xperc;
      }

      //lets user scroll past bottom edge of image
      if (y >= 0.01 * imgHeight) {
        yperc += 0.15 * yperc;
      }

      style.backgroundPositionX = xperc - 9 + "%";
      style.backgroundPositionY = yperc - 9 + "%";

      style.left = x - 180 + "px";
      style.top = y - 180 + "px";
    },
  },
};
</script>

<style scoped>
#img-zoomer-box {
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 1;
  margin-bottom: 10px;
}

#img-zoomer-box img {
  width: 100%;
  height: auto;
}

@supports (object-fit: contain) {
  #img-zoomer-box img {
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 23px;
  }
}

#img-1 {
  width: 100%;
  height: auto;
}

#img-zoomer-box:hover,
#img-zoomer-box:active {
  cursor: zoom-in;
  display: block;
}

#img-zoomer-box:hover #img-2,
#img-zoomer-box:active #img-2 {
  opacity: 1;
}
#img-2 {
  width: 340px;
  height: 340px;
  box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  position: absolute;
  opacity: 0;
  border: 4px solid whitesmoke;
  z-index: 99;
  border-radius: 100%;
  display: block;
  transition: opacity 0.2s;
}
</style>